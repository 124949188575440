
import { ROOT_ACTIONS } from '@/store/actions';
import { Program, StateType } from '@/types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { USER_GETTERS } from '@/store/user/getters';
import { Timestamp, addDoc, collection, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '@/firebase';
import { User } from '@/store/user/types';

@Component({
  components: {
    ConfirmationDialog: () => import('@/components/dialogs/ConfirmationDialog.vue'),
    SuspendProgram: () => import('@/components/dialogs/SuspendProgram.vue'),
    ResumeProgram: () => import('@/components/dialogs/ResumeProgram.vue'),
    DeleteProgram: () => import('@/components/dialogs/DeleteProgram.vue')
  }
})
export default class ProgramControls extends Vue {
  @Prop({ required: true }) program!: Program;


  dialogs = {
    suspend: false,
    resume: false,
    archive: false,
    dearchive: false,
    delete: false,
  }

  get isAdmin(): boolean {
    return this.$store.getters[USER_GETTERS.ACTING_USER].type == 'ADMIN'
  }

  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }


  get isSuspended() {
    return this.program!.state === StateType.SUSPENDED
  }

  get currentStatus(): string {
    if (this.program?.isArchived) return this.$t('archived').toString()
    if (this.isSuspended) return this.$t('suspended').toString()

    return this.$t('active').toString()
  }

  async archiveProgam() {
    try {
      await updateDoc(doc(firestore, 'programs', this.program.id), {
        isArchived: true
      })
      await addDoc(collection(firestore, 'program-states'), {
        user: this.user.id,
        date: Timestamp.now(),
        programId: this.program.id,
        type: StateType.ARCHIVED
      })
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'success',
        text: 'Gearchiveerd'
      })
      this.$emit('refresh')
      this.dialogs.archive = false;
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    }
  }

  async deArchiveProgam() {
    try {
      await updateDoc(doc(firestore, 'programs', this.program.id), {
        isArchived: false
      })
      await addDoc(collection(firestore, 'program-states'), {
        user: this.user.id,
        date: Timestamp.now(),
        programId: this.program.id,
        type: StateType.RESUMED
      })
      this.$emit('refresh')
      this.dialogs.dearchive = false;
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'success',
        text: 'Gedearchiveerd'
      })
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    }
  }

  async deleteProgram() {
    try {

      if (this.program) {
        await deleteDoc(doc(firestore, 'programs', this.program.id))

      } else {
        throw 'program/not-loaded'
      }

      this.$router.push('/admin/presence/overview')
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'success',
        text: 'Verwijderd'
      })

    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    }
  }
}
